import { instance } from '@/utils/http.js'

//学习中心主页 学习进程
export const getLearnProcess = () => {
  return instance({
    url: '/api/v1/home/learn_process',
    method: 'get'
  })
}
//学习中心主页 学习进度页面，根据科目id及关键字获取列表
export const getLearnProcessList = (params) => {
  return instance({
    url: '/api/v1/my/my_paper',
    method: 'get',
    params
  })
}


//学习中心主页 学习进度页面，根据科目user_paper_id及关键字获取列表
export const delPrgoressPaper = (data) => {
  return instance({
    url: '/api/v1/my/del_paper',
    method: 'POST',
    data
  })
}
