import { instance } from '@/utils/http.js'

//学习中心主页 线上答疑列表
export const getOnlineAnswer = () => {
  return instance({
    url: '/api/v1/home/online_answer',
    method: 'get'
  })
}

//答疑 帖子内容板块
export const getDiscussBoard = () => {
  return instance({
    url: '/api/v1/discuss/get_user_boards',
    method: 'get'
  })
}
//答疑 根据discuss_board板块，discuss_post_type，keywords获取帖子列表
export const getDiscussList = (params) => {
  return instance({
    url: '/api/v1/discuss/index',
    method: 'get',
    params
  })
}

// 答疑》发帖
export const addPost = (data) => {
  return instance({
    url: '/api/v1/discuss/new_post_done',
    method: 'post',
    data
  })
}

// 答疑》获取帖子详情
export const getPostInfo = (params) => {
  return instance({
    url: '/api/v1/discuss/show_post',
    method: 'get',
    params
  })
}

// 帖子详情》点赞
export const addUpvote = (data) => {
  return instance({
    url: '/api/v1/discuss/add_upvote',
    method: 'post',
    data
  })
}
// 帖子详情》回复
export const replyPost = (data) => {
  return instance({
    url: '/api/v1/discuss/re_post_done',
    method: 'post',
    data
  })
}